import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import ArticleGrid from "../components/ArticleGrid"
import Article from "../components/Article"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const siteTitle = data.site.siteMetadata.title
  const tagHeader = `${totalCount} post${
    totalCount == 1 ? "" : "s"
  } tagged with '${tag}'`

  return (
    <Layout location={location} title={siteTitle}>
      <header style={{ width: "100%", textAlign: "center" }}>
        <h1 style={{ marginBottom: rhythm(2 / 4), marginTop: rhythm(2 / 4) }}>
          {tagHeader}
        </h1>
      </header>

      <ArticleGrid>
        {edges.map(({ node }) => {
          console.log(node)
          return <Article key={node.fields.slug} node={node} />
        })}
      </ArticleGrid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
          }
        }
      }
    }
  }
`

export default Tags
